// @Libs
import * as React from "react"
import { useState, useEffect } from "react"
import { NavLink, useHistory, useLocation } from "react-router-dom"
import { Button, Dropdown, message, MessageArgsProps, Modal, notification, Popover, Tooltip } from "antd"
// @Components
import { NotificationsWidget } from "lib/components/NotificationsWidget/NotificationsWidget"
import { BillingCurrentPlan } from "lib/components/BillingCurrentPlan/BillingCurrentPlan"
import { handleError } from "lib/components/components"
// @Icons
import Icon, {
  ApiFilled,
  AreaChartOutlined,
  CloudFilled,
  HomeFilled,
  LogoutOutlined,
  InfoCircleOutlined,
  NotificationFilled,
  SettingOutlined,
  ThunderboltFilled,
  UserOutlined,
} from "@ant-design/icons"
import { ReactComponent as JitsuLogo } from "icons/go-flow.svg"
import { ReactComponent as Cross } from "icons/cross.svg"
import { ReactComponent as DbtCloudIcon } from "icons/dbtCloud.svg"
import { ReactComponent as KeyIcon } from "icons/key.svg"
import { ReactComponent as DownloadIcon } from "icons/download.svg"
import { ReactComponent as GlobeIcon } from "icons/globe.svg"
import { ReactComponent as SourcesIcon } from "icons/sources.svg"
import { ReactComponent as DestinationsIcon } from "icons/destinations.svg"
import { ReactComponent as SettingsIcon } from "icons/settings.svg"
import { ReactComponent as DomainsIcon } from "icons/domains.svg"
import { ReactComponent as StatsIcon } from "icons/stats.svg"
import { ReactComponent as LiveEventsIcon } from "icons/livevents.svg"
import { ReactComponent as HomeIcon } from "icons/home.svg"
import { ReactComponent as logoNext } from "icons/logo-next.svg"
import classNames from "classnames"
// @Utils
import { reloadPage } from "lib/commons/utils"
// @Services
import { useServices } from "hooks/useServices"
import { AnalyticsBlock } from "lib/services/analytics"
import { CurrentSubscription } from "lib/services/billing"
// @Styles
import styles from "./Layout.module.less"
// @Misc
import ApplicationServices, { FeatureSettings } from "./lib/services/ApplicationServices"
import { usePersistentState } from "./hooks/usePersistentState"
import { SupportOptions } from "lib/components/SupportOptions/SupportOptions"
import { actionNotification } from "ui/components/ActionNotification/ActionNotification"
import { useClickOutsideRef } from "hooks/useClickOutsideRef"
import { Breadcrumbs } from "./ui/components/Breadcrumbs/Breadcrumbs"
import ProjectLink, { projectRoute, stripProjectFromRoute } from "./lib/components/ProjectLink/ProjectLink"
import { ProjectPermission, User } from "./generated/conf-openapi"
import { showProjectSwitchModal } from "./lib/components/ProjectSwitch/ProjectSwitch"
import { BillingPlanOptionsModal } from "lib/components/BillingPlanOptions/BillingPlanOptions"
import EditOutlined from "@ant-design/icons/lib/icons/EditOutlined"
import useProject from "./hooks/useProject"
import { allPermissions } from "./lib/services/permissions"
import AppNavMenu from "./ui/components/Layout/AppNavMenu"
import { data } from "./ui/components/goPlatform/goPlatformMain"
import { ServerStorage } from "./lib/services/ServerStorage"
import logo from "./icons/logo.svg"
import { ClassicProjectStatus } from "./lib/services/jitsu-next-ee-client"
import goChatSnippet from "./ui/components/goPlatform/goChatSnippet"
import TopNavBarGradient from "assets/topNavBarGradient"
import CienceNavMenu from "ui/components/CienceNavMenu"
import { ChevronDown } from "tabler-icons-react"
import OrgSwitchIcon from "OrgSwitchIcon"
import { appcuesIdentify, appcuesLogout } from "./utils/appcues"

type MenuItem = {
  icon: React.ReactNode
  title: React.ReactNode
  link: string
  color: string
  enabled: (f: FeatureSettings) => boolean
}

const makeItem = (
  icon: React.ReactNode,
  title: React.ReactNode,
  link: string,
  color: string,
  enabled: (f: FeatureSettings) => boolean = () => true
): MenuItem => {
  return { icon, title, link, color, enabled }
}

function usePageLocation(): string {
  const location = stripProjectFromRoute(useLocation().pathname)
  const canonicalPath = location === "/" || location === "" ? "/connections" : location
  return canonicalPath.split("/")[1]
}

export const ApplicationMenu: React.FC<{ expanded: boolean }> = ({ expanded }) => {
  const services = useServices()
  const project = useProject()
  const permissions: ProjectPermission[] = project.permissions || allPermissions
  const key = usePageLocation()
  // const menuItems = [
  //   makeItem(<HomeFilled />, "Home", "/connections", "#77c593"),
  //   makeItem(<ThunderboltFilled />, "Live Events", "/events-stream", "#fccd04"),
  //   makeItem(<AreaChartOutlined />, "Statistics", "/dashboard", "#88bdbc"),
  //   makeItem(<Icon component={KeyIcon} />, "API Keys", "/api-keys", "#d79922"),
  //   makeItem(<ApiFilled />, "Sources", "/sources", "#d83f87"),
  //   makeItem(<NotificationFilled />, "Destinations", "/destinations", "#4056a1"),
  //   makeItem(<Icon component={DbtCloudIcon} />, "dbt Cloud Integration", "/dbtcloud", "#e76e52"),
  //   permissions.includes(ProjectPermission.MODIFY_CONFIG) &&
  //     makeItem(<SettingOutlined />, "Project settings", "/project-settings", "#0d6050"),
  //   makeItem(<Icon component={GlobeIcon} />, "Geo data resolver", "/geo-data-resolver", "#41b3a3"),
  //   permissions.includes(ProjectPermission.MODIFY_CONFIG) &&
  //     makeItem(<CloudFilled />, "Custom Domains", "/domains", "#5ab9ea", f => f.enableCustomDomains),
  //   permissions.includes(ProjectPermission.MODIFY_CONFIG) &&
  //     makeItem(<Icon component={DownloadIcon} />, "Download Config", "/cfg-download", "#14a76c"),
  // ]
  const menuItems = [
    makeItem(<Icon component={HomeIcon} />, "Home", "/connections", "none"),
    makeItem(<Icon component={LiveEventsIcon} />, "Live Events", "/events-stream", "none"),
    makeItem(<Icon component={StatsIcon} />, "Statistics", "/dashboard", "none"),
    makeItem(<Icon component={KeyIcon} />, "API Keys", "/api-keys", "none"),
    makeItem(<Icon component={SourcesIcon} />, "Sources", "/sources", "none"),
    makeItem(<Icon component={DestinationsIcon} />, "Destinations", "/destinations", "none"),
    makeItem(<Icon component={DbtCloudIcon} />, "dbt Cloud Integration", "/dbtcloud", "none"),
    makeItem(<Icon component={SettingsIcon} />, "Project settings", "/project-settings", "none"),
    // makeItem(<Icon component={GlobeIcon} />, "Geo data resolver", "/geo-data-resolver", "none"),
    // makeItem(<CloudFilled />, "Custom Domains", "/domains", "#5ab9ea", f => f.enableCustomDomains),
    // makeItem(<Icon component={DownloadIcon} />, "Download Config", "/cfg-download", "none"),
  ]
  const keyWithDash = key.replace("_", "-")
  const Wrapper = React.useMemo<React.FC<{ title?: string | React.ReactNode }>>(
    () =>
      expanded
        ? ({ children }) => <>{children}</>
        : ({ title, children }) => (
            <Tooltip title={title} placement="right" mouseEnterDelay={0} mouseLeaveDelay={0}>
              {children}
            </Tooltip>
          ),
    [expanded]
  )

  return (
    <div className={`flex pl-5 ${styles.sideBarContent_applicationMenu}`}>
      {menuItems.map(item => {
        console.log({ link: item.link, key, keyWithDash })

        const selected = item.link === "/" + key || item.link === "/" + keyWithDash
        const enabled = item.enabled(services.features)
        return (
          enabled && (
            <ProjectLink to={item.link} key={item.link}>
              <Wrapper title={item.title}>
                <div
                  key={item.link}
                  className={`flex items-center ${
                    selected ? styles.selectedMenuItem : styles.sideBarContent_item__withRightBorder
                  } ${styles.menuItem} whitespace-nowrap ${!selected && "text-textPale"} py-6 px-4`}
                  style={{
                    fill: item.color,
                  }}
                >
                  <i className={`block`}>{item.icon}</i>
                  {expanded && <span className="pl-2 whitespace-nowrap">{item.title}</span>}
                </div>
              </Wrapper>
            </ProjectLink>
          )
        )
      })}
    </div>
  )
}

export const ApplicationSidebar: React.FC<{}> = () => {
  const [expanded, setExpanded] = usePersistentState<boolean>(true, "jitsu_menuExpanded")

  return (
    <div className={`relative ${styles.sideBarContent}`}>
      <div className="flex flex-col items-stretch h-full">
        <div className={`page-nav flex-grow flex-shrink min-h-0 ${styles.sideBarContent_item__withRightBorder}`}>
          <ApplicationMenu expanded={expanded} />
        </div>
        <div
          className={`hidden flex items-center py-2 ${styles.sideBarContent_item__withRightBorder}`}
          onClick={() => setExpanded(!expanded)}
        >
          <Button
            type="text"
            className={styles.expandButton}
            icon={
              <i className={`inline-block text-center align-baseline w-3 h-full ${expanded ? "mr-2" : ""}`}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className={`transform ${expanded ? "rotate-90" : "-rotate-90"}`}
                  viewBox="0 0 24 24"
                  fill="currentColor"
                >
                  <path
                    fill="currentColor"
                    d="M14.121,13.879c-0.586-0.586-6.414-6.414-7-7c-1.172-1.172-3.071-1.172-4.243,0	c-1.172,1.172-1.172,3.071,0,4.243c0.586,0.586,6.414,6.414,7,7c1.172,1.172,3.071,1.172,4.243,0	C15.293,16.95,15.293,15.05,14.121,13.879z"
                  />
                  <path
                    fill="currentColor"
                    d="M14.121,18.121c0.586-0.586,6.414-6.414,7-7c1.172-1.172,1.172-3.071,0-4.243c-1.172-1.172-3.071-1.172-4.243,0	c-0.586,0.586-6.414,6.414-7,7c-1.172,1.172-1.172,3.071,0,4.243C11.05,19.293,12.95,19.293,14.121,18.121z"
                  />
                </svg>
              </i>
            }
          >
            {expanded ? <span className="inline-block h-full">{"Minimize Sidebar"}</span> : null}
          </Button>
        </div>
      </div>
    </div>
  )
}

export type PageHeaderProps = {
  user: User
  plan: CurrentSubscription
  classicProject: ClassicProjectStatus
}

function abbr(user: User) {
  return user.name
    ?.split(" ")
    .filter(part => part.length > 0)
    .map(part => part[0])
    .join("")
    .toUpperCase()
}

function removeGoChatSnippet() {
  const snippet = document.getElementById("goChat")
  snippet?.remove()
  const div = document.getElementById("ChaskiqMessengerRoot")
  div?.remove()
}

export const PageHeader: React.FC<PageHeaderProps> = ({ plan, user, classicProject, children }) => {
  const services = useServices()
  const history = useHistory()

  const showSettings = React.useCallback<() => void>(() => history.push("/user/settings"), [history])
  const [dropdownVisible, setDropdownVisible] = useState(false)
  return (
    <div className="topBar mb-0 flex flex-nowrap relative justify-center items-center">
      <div className="absolute" style={{ top: 0, left: "10%", zIndex: 0 }}>
        <TopNavBarGradient />
      </div>
      <div className="flex-grow relative z-10">
        <div className="flex items-center h-full">{children}</div>
      </div>
      <div className={`relative z-10 flex-shrink flex justify-center items-center mx-1`}>
        {classicProject && (
          <Popover
            placement={"bottomRight"}
            content={
              <div className={"w-96"}>
                What is <b>Cience Next</b>?
                <br />
                <br />
                <b>Cience Next</b> is a new version of Cience with new user interface and faster, better scalable
                backend.
                <br />
                <br />
                <a target={"_blank"} rel={"noreferrer noopener"} href={"https://docs.jitsu.com/next-vs-classic"}>
                  Learn more
                </a>
              </div>
            }
          >
            <span
              onClick={e => {
                window.location.href = `${process.env.JITSU_NEXT_URL}?token=${
                  classicProject.token
                }&projectName=${encodeURIComponent(classicProject.name)}`
              }}
              className={"cursor-pointer"}
            >
              <Icon className={"mr-1"} component={logoNext} />
              Switch to <b>Cience Next</b> (beta), a new version of Cience Platform
              <InfoCircleOutlined className={"ml-1"} />
            </span>
          </Popover>
        )}
      </div>
      <div className={`relative z-10 flex-shrink flex justify-center items-center mx-1`}>
        <NotificationsWidget />
      </div>
      <div
        onClick={() => {
          showProjectSwitchModal()
        }}
        className={classNames(
          "relative z-10 flex gap-2 pointer-events-auto cursor-pointer items-center p-2 rounded-full px-2 pl-4",
          styles.switchProject
        )}
      >
        <OrgSwitchIcon /> <ChevronDown stroke="white" />
      </div>
      <div className="relative z-10 flex-shrink flex justify-center items-center mx-4">
        <Dropdown
          key={"userMenuDropdown"}
          trigger={["click"]}
          visible={dropdownVisible}
          overlay={
            <CienceNavMenu
              onAccount={() => {
                showSettings()
              }}
              onLogout={() => {
                appcuesLogout()
                removeGoChatSnippet()
                services.userService.removeAuth(reloadPage)
              }}
            />
          }
          onVisibleChange={vis => setDropdownVisible(vis)}
        >
          <div className="flex items-center gap-4 cursor-pointer" style={{ color: "#fff" }}>
            <div
              className="flex rounded-full bg-[#1a557b] gap-4 w-8 h-8 justify-center items-center font-bold"
              style={{ letterSpacing: "-1.5px", background: "#1a557b" }}
            >
              {abbr(user) || <UserOutlined />}
            </div>
            <div className="flex flex-col gap-2">
              <span className="max-w-[200px] text-ellipsis overflow-hidden">{user?.email}</span>
              <span className="text-[#7396af] max-w-[200px] text-ellipsis overflow-hidden"> </span>
            </div>
            <ChevronDown size={18} />
          </div>
        </Dropdown>
      </div>
    </div>
  )
}

export const DropdownMenu: React.FC<{ user: User; plan: CurrentSubscription; hideMenu: () => void }> = ({
  plan,
  user,
  hideMenu,
}) => {
  const services = useServices()
  const history = useHistory()

  const showSettings = React.useCallback<() => void>(() => history.push("/user/settings"), [history])

  return (
    <div>
      <div className="py-5 border-b px-5 flex flex-col">
        <div className="text-text font-semibold">{user.name}</div>
        <div className="text-secondaryText">{user.email}</div>
      </div>
      <div className="py-2 border-b border-main px-5 flex flex-col">
        <div>
          {services.activeProject?.name && (
            <>
              <span className="font-semibold">{services.activeProject.name} </span>
              <Button
                size="small"
                type="text"
                icon={<EditOutlined />}
                onClick={() => {
                  hideMenu()
                  history.push(projectRoute("/project-settings"))
                }}
              />
            </>
          )}
          <div className="text-xs flex flex-col">
            <a
              onClick={() => {
                hideMenu()
                showProjectSwitchModal()
              }}
            >
              Switch project
            </a>
          </div>
        </div>
      </div>
      {services.features.billingEnabled && services.applicationConfiguration.billingUrl && (
        <div className="py-5 border-b border-main px-5 flex flex-col items-start">
          <BillingCurrentPlan planStatus={plan} onPlanChangeModalOpen={hideMenu} />
        </div>
      )}
      <div className="p-2 flex flex-col items-stretch">
        <Button type="text" className="text-left" key="settings" icon={<SettingOutlined />} onClick={showSettings}>
          Settings
        </Button>
        <Button
          className="text-left"
          type="text"
          key="logout"
          icon={<LogoutOutlined />}
          onClick={() => {
            removeGoChatSnippet()
            services.userService.removeAuth(reloadPage)
          }}
        >
          Logout
        </Button>
      </div>
    </div>
  )
}

function handleBillingMessage(params) {
  if (!params.get("billingMessage")) {
    return
  }

  ;(params.get("billingStatus") === "error" ? notification.error : notification.success)({
    message: params.get("billingMessage"),
    duration: 5,
  })
}

export const ApplicationPage: React.FC<{ classicProject: ClassicProjectStatus }> = ({ classicProject, children }) => {
  const [links, setLinks] = useState([{ style: { width: 0 }, href: "", class: "", id: "", name: "" }])
  const [ssoUserId, setSsoUserId] = useState("")

  useEffect(() => {
    function getUser() {
      return services.userService.getUserSso()
    }
    getUser().then((res: any) => {
      const { _sso_subject } = res
      const { _sso_profile } = res
      const snippet = document.getElementById("goChat")
      if (_sso_profile && !snippet) goChatSnippet(_sso_profile.email, _sso_profile.name)

      setSsoUserId(_sso_subject)
      appcuesIdentify(ssoUserId, _sso_profile.email, services.activeProject.name)
    })

    if (typeof window !== "undefined") {
      data.goPlatformApi({ userId: ssoUserId }).then(res => {
        setLinks(res)
      })
    }
  }, [ssoUserId])

  const services = useServices()
  handleBillingMessage(new URLSearchParams(useLocation().search))
  return (
    <div className={styles.applicationPage}>
      <div className={classNames(styles.rightbar)}>
        <PageHeader
          classicProject={classicProject}
          user={services.userService.getUser()}
          plan={services.currentSubscription}
        >
          <div className="flex-shrink flex justify-center items-center ml-4">
            <AppNavMenu appNav={links} />
          </div>
          <div className={`px-4 ${styles.sideBarContent_item__withRightBorder} app-logo-wrapper`}>
            <NavLink to="/" className={`text-center block overflow-hidden }`}>
              <JitsuLogo style={{ width: "201px", height: "44px" }} />
            </NavLink>
          </div>
          {/* <Breadcrumbs /> */}
        </PageHeader>
        <ApplicationSidebar />
        <div className={styles.applicationPageComponent}>{children}</div>
      </div>
    </div>
  )
}

export const SlackChatWidget: React.FC<{}> = () => {
  const services = useServices()
  const [popoverVisible, setPopoverVisible] = useState<boolean>(false)
  const [upgradeDialogVisible, setUpgradeDialogVisible] = useState<boolean>(false)

  const [popoverContentRef, buttonRef] = useClickOutsideRef<HTMLDivElement, HTMLDivElement>(() => {
    setPopoverVisible(false)
  })

  const disablePrivateChannelButton: boolean = services.currentSubscription?.currentPlan?.id === "free"
  const isJitsuCloud: boolean = services.features.environment === "jitsu_cloud"
  const isPrivateSupportAvailable: boolean = services.slackApiSercice?.supportApiAvailable

  const handleUpgradeClick = () => {
    setPopoverVisible(false)
    setUpgradeDialogVisible(true)
  }

  const handleJoinPublicChannel = React.useCallback(() => {
    services.analyticsService.track("support_slack_public")
    window.open("https://cience.com/slack", "_blank")
  }, [])

  const handleJoinPrivateChannel = React.useCallback(async () => {
    services.analyticsService.track("support_slack_private")
    try {
      const invitationUrl = await services.slackApiSercice?.createPrivateSupportChannel(
        services.activeProject.id,
        services.activeProject.name
      )
      window.open(invitationUrl, "_blank")
    } catch (_error) {
      const error = _error instanceof Error ? _error : new Error(_error)
      actionNotification.error(
        `Failed to join a private channel due to internal error. Please, contact support via email or file an issue. Description:\n${error}`
      )
      services.analyticsService.track("support_slack_private_error", error)
    }
  }, [])

  const handleSupportEmailCopy = React.useCallback(() => {
    services.analyticsService.track("support_email_copied")
  }, [])

  return (
    <>
      <Popover
        trigger="click"
        placement="leftBottom"
        visible={popoverVisible}
        content={
          <SupportOptions
            ref={popoverContentRef}
            showEmailOption={isJitsuCloud}
            showPrivateChannelOption={isJitsuCloud && isPrivateSupportAvailable}
            disablePrivateChannelButton={disablePrivateChannelButton}
            privateChannelButtonDescription={
              disablePrivateChannelButton ? (
                <span className="text-xs text-secondaryText mb-3">
                  <a role="button" className="text-xs" onClick={handleUpgradeClick}>
                    Upgrade
                  </a>
                  {" to use this feature"}
                </span>
              ) : null
            }
            onPublicChannelClick={handleJoinPublicChannel}
            onPrivateChannelClick={handleJoinPrivateChannel}
            onEmailCopyClick={handleSupportEmailCopy}
          />
        }
      >
        <div
          ref={buttonRef}
          id="jitsuSlackWidget"
          onClick={() => {
            services.analyticsService.track("slack_invitation_open")
            setPopoverVisible(visible => !visible)
          }}
          className="hidden fixed bottom-5 right-5 rounded-full bg-primary text-bgComponent w-12 h-12 flex justify-center items-center cursor-pointer hover:bg-primaryHover"
        >
          <span
            className={`absolute top-0 left-0 h-full w-full flex justify-center items-center text-xl transition-all duration-300 transform-gpu ${
              popoverVisible ? "opacity-100 scale-100" : "opacity-0 scale-50"
            }`}
          >
            <span className="block h-4 w-4 transform-gpu -translate-y-1/2">
              <Cross />
            </span>
          </span>
          <span
            className={`absolute top-3 left-3 transition-all duration-300 transform-gpu ${
              popoverVisible ? "opacity-0 scale-50" : "opacity-100 scale-100"
            }`}
          >
            <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
              <path d="M 4 3 C 2.9 3 2 3.9 2 5 L 2 15.792969 C 2 16.237969 2.5385156 16.461484 2.8535156 16.146484 L 5 14 L 14 14 C 15.1 14 16 13.1 16 12 L 16 5 C 16 3.9 15.1 3 14 3 L 4 3 z M 18 8 L 18 12 C 18 14.209 16.209 16 14 16 L 8 16 L 8 17 C 8 18.1 8.9 19 10 19 L 19 19 L 21.146484 21.146484 C 21.461484 21.461484 22 21.237969 22 20.792969 L 22 10 C 22 8.9 21.1 8 20 8 L 18 8 z" />
            </svg>
          </span>
        </div>
      </Popover>
      <BillingPlanOptionsModal
        planStatus={services.currentSubscription}
        onCancel={() => {
          setUpgradeDialogVisible(false)
        }}
        visible={upgradeDialogVisible}
      />
    </>
  )
}

const EmailIsNotConfirmedMessage: React.FC<{ messageKey: React.Key }> = ({ messageKey }) => {
  const services = useServices()
  const [isSendingVerification, setIsSendingVerification] = useState<boolean>(false)

  const handleDestroyMessage = () => message.destroy(messageKey)
  const handleresendConfirmationLink = async () => {
    setIsSendingVerification(true)
    try {
      await services.userService.sendConfirmationEmail()
    } finally {
      setIsSendingVerification(false)
    }
    handleDestroyMessage()
  }
  return (
    <span className="flex flex-col items-center mt-1">
      <span>
        <span>{"Email "}</span>
        {services.userService.getUser()?.email ? (
          <span className={`font-semibold ${styles.emailHighlight}`}>{services.userService.getUser()?.email}</span>
        ) : (
          ""
        )}
        <span>
          {` is not verified. Please, follow the instructions in your email
            to complete the verification process.`}
        </span>
      </span>
      <span>
        <Button type="link" loading={isSendingVerification} onClick={handleresendConfirmationLink}>
          {"Resend verification link"}
        </Button>
        <Button type="text" onClick={handleDestroyMessage}>
          {"Close"}
        </Button>
      </span>
    </span>
  )
}

const MESSAGE_KEY = "email-not-confirmed-message"

export const emailIsNotConfirmedMessageConfig: MessageArgsProps = {
  type: "error",
  key: MESSAGE_KEY,
  duration: null,
  icon: <>{null}</>,
  content: <EmailIsNotConfirmedMessage messageKey={MESSAGE_KEY} />,
}
